<template>
  <b-card
    title="Müşteri Bilgileri"
  >
    <b-row>
      <b-col>
        <b-form-group
          label="Firma Ünvanı"
          label-for="company"
        >
          <validation-provider
            #default="{ errors }"
            name="Firma Ünvanı"
            rules="required"
          >
            <b-form-input
              id="company"
              v-model="form.company"
              placeholder="Firma Ünvanı"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="TC Kimlik No / Vergi No"
          label-for="taxno"
        >
          <validation-provider
            #default="{ errors }"
            name="TC Kimlik No / Vergi No"
            rules="required"
          >
            <b-form-input
              id="taxno"
              v-model="form.taxNo"
              placeholder="TC Kimlik No / Vergi No"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Vergi Dairesi"
          label-for="taxoffice"
        >
          <validation-provider
            #default="{ errors }"
            name="Vergi Dairesi"
            rules="required"
          >
            <b-form-input
              id="taxoffice"
              v-model="form.taxOffice"
              placeholder="Vergi Dairesi"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label="Telefon"
      label-for="phone"
    >
      <b-input-group>
        <b-input-group-prepend is-text>
          TR (+90)
        </b-input-group-prepend>
        <cleave
          id="phone"
          v-model="form.phone"
          class="form-control"
          :raw="true"
          :options="options.phone"
          placeholder="224 224 00 55"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Adres"
      label-for="address"
    >
      <b-form-textarea
        id="address"
        v-model="form.address"
        placeholder="Adres"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'Customer',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
