<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col cols="12">
        <announcements />
      </b-col>
      <b-col
        cols="12"
      >
        <customer />
      </b-col>
      <b-col
        cols="12"
      >
        <payment-form />
      </b-col>
    </b-row>
    <payment3-d v-if="getPaymentSendForm.modal" />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import Announcements from '@/views/Payment/Components/Announcements.vue'
import Customer from '@/views/Payment/Components/Customer.vue'
import PaymentForm from '@/views/Payment/Components/PaymentForm.vue'
import Payment3D from '@/views/Payment/Components/Payment3D.vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    ValidationObserver,
    Customer,
    PaymentForm,
    Payment3D,
    Announcements,
  },
  data() {
    return {
      required,
      locale: 'tr',
      modalShow: false,
    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    getPaymentSendForm() {
      return this.$store.getters['payment/getPaymentSendForm']
    },
  },
  created() {
    localize(this.locale)
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.$store.dispatch('payment/resetForm')
    },
  },
}
</script>
