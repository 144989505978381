<template>
  <div>
    <b-table-simple
      hover
      caption-top
      responsive
      bordered
      striped
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="(item,ikey) in installments"
            :key="ikey"
            class="text-left font-small-1"
          >
            {{ item.title }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(row,bkey) in installments[0].values"
          :key="bkey"
        >
          <b-td>
            {{ row.text }}
          </b-td>
          <template v-for="(installment,rkey) in installments">
            <b-td
              v-if="rkey > 0"
              :key="rkey"
            >
              <b-form-radio
                v-if="installment.values[bkey].text"
                v-model="form.selectInstallment"
                :value="installment.id_pos_configs + '_' + installment.values[bkey].installment + '_' + installment.values[bkey].rate"
                name="installment"
              >
                <template v-if="installment.values[bkey].installment > 0">
                  <div class="font-small-2 font-weight-bolder">
                    {{ (calcInstallment(installment.values[bkey].rate) / installment.values[bkey].installment) | toCurrency }} ₺
                  </div>
                  <div class="text-warning font-small-2">
                    {{ installment.values[bkey].text }}
                  </div>
                </template>
                <template v-else>
                  <div class="font-small-2 font-weight-bolder">
                    {{ calcInstallment(installment.values[bkey].rate) | toCurrency }} ₺
                  </div>
                  <div class="text-warning font-small-2">
                    Tek Çekim
                  </div>
                </template>
              </b-form-radio>
              <div v-else>
                -
              </div>
            </b-td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd, BFormRadio,
} from 'bootstrap-vue'

export default {
  name: 'InstalmentVue',
  components: {
    BTableSimple, BThead, BTbody, BTr, BTh, BTd, BFormRadio,
  },
  data() {
    return {
      selectInstallment: null,
    }
  },
  computed: {
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    installments() {
      return this.$store.getters['posConfigs/getInstallments']
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        const selectData = val.selectInstallment.split('_')
        const idPosConfigs = selectData[0]
        const installment = selectData[1]
        const rate = selectData[2]
        if (this.form.id_pos_configs !== idPosConfigs) {
          this.getPosConfig(idPosConfigs)
        }
        this.form.id_pos_configs = idPosConfigs
        this.form.installment = installment
        if (this.form.amount > 0) {
          this.form.pay_amount = this.calcInstallment(rate)
        }
      },
    },
  },
  methods: {
    calcInstallment(rate) {
      const rateFloat = parseFloat(rate)
      const amount = parseFloat(this.form.amount)
      let result = amount
      if (amount) {
        if (rateFloat > 0) {
          result = amount + (amount * rateFloat) / 100
        }
      }
      return result.toFixed(2)
    },
    getPosConfig(id) {
      this.$store.dispatch('posConfigs/pos_configPay3d', id)
    },
  },
}
</script>

<style scoped></style>
