<template>
  <b-modal
    v-model="getPaymentSendForm.modal"
    ok-only
    hide-footer
    centered
    size="lg"
    :title="(getPaymentSendForm.pay3d)? '3D Güvenli Ödeme' : 'Ödeme İşlem Sonucu'"
  >
    <b-card-text v-if="pay3dStatus">
      <b-alert
        v-if="getPaymentSendForm.error"
        variant="danger"
        show
      >
        <div class="alert-body text-center">
          <div>
            <FeatherIcon
              icon="XIcon"
              size="32"
            />
          </div>
          <p class="lead">
            {{ getPaymentSendForm.error }}
          </p>
        </div>
      </b-alert>
      <div
        v-if="getPaymentSendForm.action"
        class="text-center"
      >
        <b-form
          v-if="getPaymentSendForm.action"
          id="payment_form"
          method="post"
          :action="getPaymentSendForm.action"
          target="payment_form"
        >
          <input
            v-for="(element,key) in getPaymentSendForm.elements"
            :key="key"
            type="hidden"
            :name="key"
            :value="element"
          >
          <div
            v-show="!submited"
            class="mb-2 mt-1"
          >
            <b-alert
              variant="success"
              show
            >
              <div class="alert-body">
                <div>
                  Banka güvenli ödeme sayfasına yönlendirileceksiniz.
                  <div
                    v-if="form.installment > 0"
                    class="mt-1"
                  >
                    Ödeme Tipi: {{ form.installment }} Taksit
                  </div>
                  <div
                    v-else
                    class="mt-1"
                  >
                    Ödeme Tipi: Tek Çekim
                  </div>
                </div>
                <div class="font-weight-bolder font-medium-3 mt-1">
                  Ödenecek Tutar {{ form.pay_amount | toCurrency }} ₺
                </div>
              </div>
            </b-alert>
            <b-button
              type="submit"
              variant="success"
              @click="submitForm"
            >
              ÖDEMEYE DEVAM ET
            </b-button>
          </div>
        </b-form>
        <div
          v-show="submited"
          class="embed-responsive embed-responsive-4by3"
        >
          <iframe
            class="col-12 embed-responsive-item"
            name="payment_form"
            border="0"
          >
            Tarayıcınız satır içi çerçeveleri desteklemiyor.
          </iframe>
        </div>
      </div>
    </b-card-text>
    <b-card-text v-if="!pay3dStatus">
      <b-alert
        v-if="!getPaymentSendForm.result.approved"
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <b-spinner
            class="mt-1"
            type="grow"
            label="Yükleniyor..."
          />
          <p class="lead mt-1">
            Bankadan yanıt bekleniyor...
          </p>
        </div>
      </b-alert>
      <b-alert
        v-if="getPaymentSendForm.error"
        variant="danger"
        show
      >
        <div class="alert-body text-center">
          <div>
            <FeatherIcon
              icon="XIcon"
              size="32"
            />
          </div>
          <p class="lead">
            {{ getPaymentSendForm.error }}
          </p>
        </div>
      </b-alert>
      <div v-if="getPaymentSendForm.pay3d === false">
        <b-alert
          v-if="getPaymentSendForm.result.approved === '1'"
          variant="success"
          show
        >
          <div class="alert-body text-center">
            <div>
              <FeatherIcon
                icon="CheckIcon"
                size="32"
              />
            </div>
            <p class="lead">
              Ödeme İşlemi Tamamlandı.
            </p>
            <b-button
              class="mt-2"
              :href="downloadURL"
              target="_blank"
              variant="success"
            >
              Makbuz Yazdır
            </b-button>
          </div>
        </b-alert>
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BForm, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'Payment3D',
  components: {
    BModal, BCardText, BForm, BButton, BAlert, BSpinner,
  },
  data() {
    return {
      submited: false,
    }
  },
  computed: {
    getPaymentSendForm() {
      return this.$store.getters['payment/getPaymentSendForm']
    },
    form() {
      return this.$store.getters['payment/getPaymentForm']
    },
    downloadURL() {
      const urlParams = [
        `&id=${this.getPaymentSendForm.result.pid}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/pdf/payments?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
    pay3dStatus() {
      return this.$store.getters['posConfigs/getPos_config_pay3d']
    },
  },
  methods: {
    submitForm() {
      this.submited = true
    },
  },
}
</script>

<style scoped>

</style>
